import { render, staticRenderFns } from "./ProductAmenity.vue?vue&type=template&id=2402391e&scoped=true"
import script from "./ProductAmenity.vue?vue&type=script&lang=js"
export * from "./ProductAmenity.vue?vue&type=script&lang=js"
import style0 from "./ProductAmenity.vue?vue&type=style&index=0&id=2402391e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2402391e",
  null
  
)

export default component.exports